@import '../../styles/utils.scss';

.modal {
    width: 100vw;
    height: 100vh;
    // height: calc(var(--vh, 1vh) * 100);
    max-height: 100vh;
    margin: 0 auto;
    position: fixed;
    opacity: 1;
    &:global(.player) {
      width: 100%;
      height: 100%;
      background: transparent;
      @include tablet {
        border-left: 20px solid $color-blue;
      }
    }
    &:global(.news) {
      width: 100%;
      height: 100%;
      background: white;
      transform: translate(100%, 0);
      border-left: 38px solid $color-blue;
      overflow-y: scroll;
    }
    &:global(.ReactModal__Content--after-open) {
      transition: opacity 0.6s ease-in-out;
      opacity: 1;
    }
    &:global(.ReactModal__Content--before-close) {
      transition: opacity 0.6s ease-in-out;
      opacity: 0;
    }
    &:global(.ReactModal__Content--after-open.player) {
      transition: translate 0.6s ease-in-out, transform 0.6s ease-in-out;
      opacity: 1;
    }
    &:global(.ReactModal__Content--before-close.player) {
      transition: translate 0.6s ease-in-out, transform 0.6s ease-in-out;
      opacity: 1;
    }
    &:global(.ReactModal__Content--after-open.news) {
      transition: translate 0.6s ease-in-out, transform 0.6s ease-in-out;
      transform: translate(0, 0);
      opacity: 1;
    }
    &:global(.ReactModal__Content--before-close.news) {
      transition: translate 0.6s ease-in-out, transform 0.6s ease-in-out;
      transform: translate(100%, 0);
      opacity: 1;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100000000;
    overflow: hidden;
    transition: opacity 0.5s ease-in-out;
    &:global(.ReactModal__Overlay--before-close) {
      transition-delay: 0.4s;
      opacity: 0;
    }
}

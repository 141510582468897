@import '../../styles/utils.scss';

.el {
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  .modal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .videoFrame {
    position: relative;
    width: 100%;
    width: 100%;
    max-width: 100%;
    height: 0;
    padding-top: 56.2%;

    iframe {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  // .hideButton {
  //   :global(.vjs-big-play-button) {
  //     display: none !important;
  //   }
  // }
  // :global(.vjs-big-play-button) {
  //   z-index: 2;
  // }
  // :global(.vjs-big-play-button) {
  //   display: inline-block !important;
  // }
  :global(.vjs-poster) {
    background-size: cover;
    // display: inline-block !important;
  }
  // &.hideButton {
  //   :global(.vjs-big-play-button) {
  //     display: none !important;
  //   }
  //   :global(.vjs-poster) {
  //     display: none !important;
  //   }
  // }
}
@import '../../styles/utils.scss';

.el {
  width: 100%;
  padding: 80px 0 58px;
  background: $color-blue;
  color: #fff;
  @include tablet {
    padding: 40px 0 35px;
  }
  .container {
    max-width: $container-md;
  }
  .top {
    border-bottom: 2px solid #525180;
    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 40px;
      @include tablet {
        flex-direction: column-reverse;
        padding-bottom: 30px;
      }
    }
  }
  .bottom {
    .container {

      padding-top: 44px;
    }
  }
  .flexrow {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include mobile {
      display: block;
    }
    & + .flexrow {
      margin-top: 50px;
      @include mobile {
        margin-top: 20px;
      }
    }
    &.last {
      flex-direction: row-reverse;
      align-items:flex-end;
      @include mobile {
        flex-direction: column;
      }
    }
    .largeTitle {
      color: $color-blue-25;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.15;
      letter-spacing: -0.03em;
      @include mobile {
        margin-bottom: 40px;
      }
    }
  }

  .sitemap {
    display: flex;
    flex-wrap: wrap;
    
    @include tablet {
      // display: block;
      margin-top: 40px;
    }

    @include mobile {
      margin-right: -7vw;
    }

    &Col {
      margin-right: 5.5vw;
      margin-bottom: 40px;

      @include mobile {
        margin-right: 7vw;
        width: 40vw;
        margin-bottom: 0;
      }

      h5, .h5 {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.44;
        margin-bottom: 13px;
        a {
          color: #fff;
          text-decoration: none;

          &:not([disabled]):hover {
            color: $color-orange;
          }
        }
        @include tablet {
          margin-bottom: 10px;
        }
      }
    }

    &Block {
      min-width: 140px;

      @include tablet {
        margin-bottom: 30px;
      }

      @include mobile {
        margin-bottom: 40px;
      }

      &.first {}
      &.last {
        margin-top: 40px;
        @include tablet {
          margin-top: 0;
        }
      }
    }

    &Link {
      display: block;
      font-size: 14px;
      line-height: 1.14;
      color: #fff;
      padding: 4px 0;

      &:hover {
        text-decoration: none;
        color: $color-orange;
      }
    }
  }

  .social {
    display: flex;
    align-items: center;

    @include mobile {
      margin-bottom: 60px;
    }

    a {
      display: block;
      margin: 0 8px;
      color: #fff;
      height:32px;
      width: 32px;

      transition: all 0.2s;

      @include mobile {
        &:first-child {
          margin-left: 0;
        }
      }

      span {
        font-size: 32px;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        text-decoration: none;
        transform: scale(1.2);
        text-shadow: 6px 6px 5px rgba(0, 0, 0, 0.25);
      }
    }
  }

  .logo {
    width: 183px;
    @include tablet {
      width: 150px;
    }
    img {
      max-width: 100%;
    }
  }
}
